<template>
  <div class="full-height">
    <b-card class="mb-0 full-height">
      <div class="d-flex align-items-center">
        <b-avatar
          :src="student.image ? student.image.full_url : null"
          size="54px"
          rounded
        />
        <div class="ml-1">
          <h4 class="mb-0">
            {{ fullName }}
          </h4>
          <p class="card-text mb-0">
            {{ studentModel.group }}
          </p>
        </div>
        <div class="flex-fill" />
        <b-button
          v-if="!noRemoval"
          class="btn-icon"
          title="Удалить"
          variant="flat-secondary"
          size="sm"
          @click="detachStudent"
        >
          <feather-icon
            size="20"
            icon="XIcon"
          />
        </b-button>
      </div>

      <div
        v-if="studentModel.parent_name"
        class="mt-2 d-flex align-center"
      >
        <div>
          <h4 class="mb-0">
            <span>{{ studentModel.parent_name }}</span>
            <span class="text-muted font-small-3 ml-50">(родитель)</span>
          </h4>
          <p
            v-if="student.phone"
            class="card-text mb-0"
          >
            +{{ student.phone }}
          </p>
        </div>
        <div class="flex-fill" />
        <div>
          <b-button
            v-if="student.phone"
            class="btn-icon"
            variant="flat-secondary"
            title="Позвонить"
            size="sm"
            :href="`tel:+${student.phone}`"
          >
            <feather-icon
              size="20"
              icon="PhoneIcon"
            />
          </b-button>
        </div>
      </div>

      <template v-if="!noStudyProgram">
        <div class="thin-divider my-2" />

        <div class="d-flex align-center">
          <div>
            <h5 class="mb-0">
              Учебная программа
            </h5>
            <span class="card-text">{{ studyProgram ? studyProgram.name : 'Не назначена' }}</span>
          </div>
          <div class="flex-fill" />
          <assign-study-program-modal
            :student="student"
            :study-program="studyProgram"
            @updated="(program) => studyProgram = {...program}"
          >
            <template #activator="{show}">
              <b-button
                variant="outline-primary"
                size="sm"
                class="mt-50"
                @click="show"
              >
                <feather-icon
                  :icon="studyProgram ? 'ExternalLinkIcon' : 'PlusIcon'"
                  class="mr-50"
                />
                <span v-if="studyProgram">Показать</span>
                <span v-else>Назначить</span>
              </b-button>
            </template>
          </assign-study-program-modal>
        </div>
      </template>

      <template v-if="false">
        <div class="thin-divider my-2" />

        <div class="d-flex align-center">
          <div>
            <h5 class="mb-0">
              Подписка
            </h5>
            <span class="card-text">{{ subscription ? '' : 'Не подключена' }}</span>
          </div>
          <div class="flex-fill" />
          <pay-subscription-modal
            :student="student"
          >
            <template #activator="{show}">
              <b-button
                variant="primary"
                size="sm"
                class="mt-50"
                @click="show"
              >
                <feather-icon
                  :icon="subscription ? 'ExternalLinkIcon' : 'PlusIcon'"
                  class="mr-50"
                />
                <span v-if="subscription">Продлить</span>
                <span v-else>Подключить</span>
              </b-button>
            </template>
          </pay-subscription-modal>
        </div>
      </template>

    </b-card>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Выбранный ученик больше не будет закреплен за Вами`"
    />
  </div>
</template>

<script>
import { BCard, BAvatar, BButton } from 'bootstrap-vue'
import { getUserFullName } from '../../helpers'
import AssignStudyProgramModal from './AssignStudyProgramModal.vue'
import ConfirmModal from '../page-elements/modals/ConfirmModal.vue'
import PaySubscriptionModal from './PaySubscriptionModal.vue'

export default {
  name: 'StudentCard',
  components: {
    PaySubscriptionModal,
    ConfirmModal,
    AssignStudyProgramModal,
    BCard,
    BAvatar,
    BButton,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    noStudyProgram: {
      type: Boolean,
      default: false,
    },
    noRemoval: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullName() {
      return getUserFullName(this.student)
    },
    studyProgram: {
      get() {
        return this.student.studyProgramStudent[0] || null
      },
      set(newValue) {
        // eslint-disable-next-line vue/no-mutating-props
        this.student.studyProgramStudent = [newValue]
      },
    },
    subscription() {
      return this.student.subscription
    },
    studentModel() {
      return this.student.usersStudent ?? {}
    },
  },
  methods: {
    async detachStudent() {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            await this.$http.delete(`/user/current-teacher-delete-student?id=${this.student.id}`)
              .then(() => {
                this.$emit('onDetach')
              })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
