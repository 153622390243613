<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="dialog"
      title="Добавление ученика"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template>
        <!-- form -->
        <b-form @submit.prevent="addStudent">

          <div class="p-2">
            <b-form-group label="Телефон ученика">
              <b-input-group>
                <!-- name -->
                <VuePhoneNumberInput
                  v-model="phone"
                  class="w-100"
                  color="#7367f0"
                  :dark="$store.state.appConfig.layout.skin === 'dark'"
                  valid-color="#28A745"
                  error-color="#DC3545"
                  no-example
                  :translations="{
                    countrySelectorLabel: 'Страна',
                    countrySelectorError: 'Ошибка',
                    phoneNumberLabel: 'введите телефон ученика',
                    example: 'Пример :'
                  }"
                  :only-countries="['RU', 'BY', 'KZ', 'AM']"
                  name="phone"
                  @update="(dt) => phonePayload = dt "
                />
              </b-input-group>
            </b-form-group>

            <template v-if="teacher">
              <template v-if="isSearching">
                <student-card-skeleton />
              </template>
              <template v-else-if="error">
                <b-alert
                  v-if="error"
                  :show="!!error"
                  variant="danger"
                  class="mb-0"
                >
                  <div class="alert-body">
                    {{ error.data ? error.data.data.message : 'Пользователь с таким номером не найден' }}
                  </div>
                </b-alert>
              </template>
              <template v-else-if="foundStudent">
                <student-card
                  :student="foundStudent"
                  no-study-program
                  no-removal
                />

                <b-row
                  dense
                  class="mt-1"
                >
                  <b-col cols="6">
                    <b-form-group label="Стоимость занятия, руб">
                      <b-input-group>
                        <b-form-input
                          v-model="lessonPrice"
                          type="number"
                          step="0.01"
                          placeholder="Введите цену"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Пробное занятие">
                      <b-button-group style="width: 100%">
                        <b-button
                          :variant="freeLesson ? 'primary' : 'outline-primary'"
                          @click="freeLesson = true"
                        >
                          Да
                        </b-button>
                        <b-button
                          :variant="!freeLesson ? 'primary' : 'outline-primary'"
                          @click="freeLesson = false"
                        >Нет</b-button>
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </template>

            <b-alert
              v-if="error && !teacher"
              :show="!!error"
              variant="danger"
              class="mb-0"
            >
              <div class="alert-body">
                {{ error.phone }}
              </div>
            </b-alert>
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <template v-if="teacher && !foundStudent">
              <b-button
                variant="primary"
                type="button"
                :disabled="!phoneIsValid"
                @click="findStudent"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                Найти
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="primary"
                type="submit"
                :disabled="!phoneIsValid"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                Добавить
              </b-button>
            </template>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="dialog = false"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BForm, BFormGroup, BInputGroup, BAlert, BRow, BCol, BFormInput, BButtonGroup,
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import StudentCard from './StudentCard.vue'
import StudentCardSkeleton from './StudentCardSkeleton.vue'
import { toast } from '../../helpers'

export default {
  name: 'AssignStudentModal',
  components: {
    StudentCardSkeleton,
    StudentCard,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    VuePhoneNumberInput,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
  },
  props: {
    teacher: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    phone: '',
    lessonPrice: null,
    freeLesson: null,
    error: null,
    phonePayload: null,
    foundStudent: null,
    isSearching: false,
  }),
  computed: {
    phoneIsValid() {
      if (!this.phonePayload) return false
      return this.phonePayload.isValid
    },
  },
  watch: {
    phone() {
      this.foundStudent = null
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    saveAndClose() {
      this.closeDialog()
    },
    show() {
      this.dialog = !this.dialog
    },
    async findStudent() {
      this.error = null
      this.isSearching = true
      const phone = this.phonePayload.formattedNumber.replace(/\D/g, '')
      this.$http.get('/user/index', { params: { phone, expand: 'usersStudent,image,studyProgramStudent' } }).then(response => {
        this.foundStudent = response?.items[0] ?? null
        if (!this.foundStudent) this.error = true
      }).catch(err => {
        this.error = err
      }).finally(() => {
        this.isSearching = false
      })
    },
    async addStudent() {
      this.error = null
      const phone = this.phonePayload.formattedNumber.replace(/\D/g, '')

      if (this.foundStudent) {
        this.$http.post('/user/add-student-teacher', {
          teacher_id: this.teacher?.user?.id,
          student_id: this.foundStudent?.id,
          lesson_price: this.lessonPrice,
          free_lesson: this.freeLesson,
        }).then(() => {
          toast({ title: 'Ученик назначен', type: 'success' })
          this.phone = ''
          this.lessonPrice = null
          this.freeLesson = false
          this.dialog = false
        }).catch(err => {
          this.error = err
        })
      } else {
        this.$http.post('/user/current-teacher-add-student', { phone }).then(() => {
          this.$emit('studentAssigned')
          this.phone = ''
          this.dialog = false
        }).catch(err => {
          this.error = err
        })
      }
    },
  },
}
</script>
