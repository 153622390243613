<template>
  <div>
    <b-row class="mb-2">
      <b-col />
      <b-col
        cols="12"
        xl="3"
      >
        <b-input-group class="bg-transparent">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            v-debounce:600ms="updateSearch"
            type="text"
            placeholder="Поиск..."
            class="bg-transparent"
          />
        </b-input-group>
      </b-col>
      <b-col
        cols="auto"
        align-self="center"
      >
        <assign-student-modal @studentAssigned="reload">
          <template #activator="{show}">
            <b-button
              variant="primary"
              @click="show"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              Добавить ученика
            </b-button>
          </template>
        </assign-student-modal>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="student in students"
        :key="student.id"
        cols="12"
        md="6"
        lg="4"
        class="mb-2"
      >
        <student-card
          :student="student"
          @onDetach="reload"
        />
      </b-col>
    </b-row>

    <infinite-loading
      ref="infiniteLoader"
      @infinite="infiniteHandler"
    >
      <div slot="spinner">
        <b-row>
          <b-col
            v-for="skel in 3"
            :key="`skel-${skel}`"
            cols="12"
            md="6"
            lg="4"
            class="mb-2"
          >
            <student-card-skeleton />
          </b-col>
        </b-row>
      </div>
      <div slot="no-more" />
      <div slot="no-results">
        <h3
          v-if="!students.length"
          class="mt-2"
        >
          <span v-if="filter.search">Ничего не найдено по запросу <b>"{{ filter.search }}"</b></span>
          <span v-else>Пока нет заявок</span>
        </h3>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import AssignStudentModal from '@/components/students/AssignStudentModal.vue'
import StudentCard from '../../components/students/StudentCard.vue'
import StudentCardSkeleton from '../../components/students/StudentCardSkeleton.vue'

export default {
  components: {
    StudentCardSkeleton,
    StudentCard,
    AssignStudentModal,
    BRow,
    BCol,
    BButton,
    BFormInput,
    InfiniteLoading,
    BInputGroup,
    BInputGroupPrepend,
  },
  data: () => ({
    students: [],
    limit: 15,
    filter: {
      search: '',
      sortBy: null,
      sortDesc: false,
    },
    abortController: null,
    meta: {
      total: null,
    },
  }),
  computed: {
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
  },
  methods: {
    updateSearch(value) {
      this.filter.search = value ?? ''
      this.reload()
    },
    reload() {
      this.students = []
      this.$refs.infiniteLoader.stateChanger.reset()
    },
    infiniteHandler($state) {
      if (this.abortController) this.abortController.abort()
      this.abortController = new AbortController()

      this.$http.get('/user/current-teacher-student-index-field', {
        params: {
          sort: this.sortBy,
          page: Math.ceil(this.students.length / this.limit || 1),
          limit: this.limit,
          singleField: this.filter.search,
          expand: 'usersStudent,image,studyProgramStudent,subscriptionStudentsCurrentTeacher',
        },
      }).then(async ({ items, _meta }) => {
        this.meta.totalCount = _meta.totalCount
        if (items.length) {
          this.students = [...this.students, ...items]
          if (items.length < this.limit) $state.complete()
          else $state.loaded()
        } else {
          $state.complete()
        }
      }).catch(err => {
        if (err?.message !== 'canceled') $state.error()
      })
    },
  },
}
</script>
