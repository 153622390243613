<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="dialog"
      title="Управление подпиской"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template>
        <!-- form -->
        <b-form>

          <div class="p-2" />
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              Оплатить
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="dialog = false"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BForm,
} from 'bootstrap-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'PaySubscriptionModal',
  components: {
    BModal,
    BButton,
    BForm,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    phone: '',
    error: null,
    phonePayload: null,
  }),
  computed: {
    phoneIsValid() {
      if (!this.phonePayload) return false
      return this.phonePayload.isValid
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    saveAndClose() {
      this.closeDialog()
    },
    show() {
      this.dialog = !this.dialog
    },
    async addStudent() {
      this.error = null
      const phone = this.phonePayload.formattedNumber.replace(/\D/g, '')
      this.$http.post('/user/current-teacher-add-student', { phone }).then(() => {
        this.$emit('studentAssigned')
        this.phone = ''
        this.dialog = false
      }).catch(err => {
        this.error = err
      })
    },
  },
}
</script>
