<template>
  <div class="full-height">
    <b-card class="mb-0 full-height">
      <div class="d-flex align-items-center">
        <skeletor
          width="54px"
          height="54px"
        />
        <div class="ml-1 flex-fill">
          <skeletor
            width="80%"
            height="22px"
            class="mb-50"
          />
          <skeletor
            width="60%"
            height="18px"
          />
        </div>
        <div class="flex-fill" />
        <skeletor
          width="24px"
          height="24px"
        />
      </div>

      <div class="mt-2 d-flex align-center">
        <div class="flex-fill">
          <skeletor
            width="70%"
            height="22px"
            class="mb-50"
          />
          <skeletor
            width="80%"
            height="18px"
          />
        </div>
        <div class="flex-fill" />
        <skeletor
          class="mt-1"
          width="24px"
          height="24px"
        />
      </div>

      <div class="thin-divider my-2" />

      <div class="mt-2 d-flex align-center">
        <div class="flex-fill">
          <skeletor
            width="100%"
            height="22px"
            class="mb-50"
          />
          <skeletor
            width="80%"
            height="18px"
          />
        </div>
        <div class="flex-fill" />
        <skeletor
          class="mt-1"
          width="130px"
          height="24px"
        />
      </div>
    </b-card>

  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'StudentCardSkeleton',
  components: {
    BCard,
  },
}
</script>

<style scoped>

</style>
